/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.button-inside {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
