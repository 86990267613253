/*!
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@import '~reveal.js/dist/reveal.css';
// Default mixins and settings -----------------
@import "~reveal.js/css/theme/template/mixins";
@import "~reveal.js/css/theme/template/settings";
// ---------------------------------------------


// Override theme settings (see ../template/settings.scss)
$backgroundColor: #191919;

$mainColor: #fff;
$headingColor: #fff;

$mainFontSize: 42px;
$mainFont: 'Source Sans Pro', Helvetica, sans-serif;
$headingFont: 'Source Sans Pro', Helvetica, sans-serif;
$headingTextShadow: none;
$headingLetterSpacing: normal;
$headingTextTransform: uppercase;
$headingFontWeight: 600;
$linkColor: #42affa;
$linkColorHover: lighten($linkColor, 15%);
$selectionBackgroundColor: lighten($linkColor, 25%);

$heading1Size: 2.5em;
$heading2Size: 1.6em;
$heading3Size: 1.3em;
$heading4Size: 1.0em;

// Change text colors against light slide backgrounds
@include light-bg-text-color(#222);


// Theme template ------------------------------
@import "~reveal.js/css/theme/template/theme";
// ---------------------------------------------
