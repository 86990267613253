/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@import '~highlight.js/styles/github';

[data-bs-theme="dark"] {
  @import '~highlight.js/styles/github-dark';
}
